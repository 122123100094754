import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import GoogleMap from "../components/googleMap"
import styled from "styled-components"
import { accent } from "../utilities"
import SEO from "../components/seo"

export default ({ data }) => {
  const post = data.pageQuery
  const site = data.siteQuery.siteMetadata

  return (
    <Layout>
       <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.metaDescription}
      />
      <div>
        <h1>{post.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        

        <StyledColumns>
        <StyledContactDetails>
          <StyledContactGroup>
          <h2>Address</h2>
          <div>{site.businessAddress}</div>
          </StyledContactGroup>
          <StyledContactGroup>
          <h2>Phone</h2>
          <a href={`tel${site.telephone}`}>{site.telephoneReadable}</a>
          </StyledContactGroup>
          <StyledContactGroup>
          <h2>Email</h2>
          <a href={`mailto:${site.email}`}>{site.email}</a>
          </StyledContactGroup>
         
         
         
        </StyledContactDetails>

        <StyledForm
          name="contact"
          method="POST"
          netlify-honeypot="bot-field"
          data-netlify="true"
        >
          <p style={{ display: "none" }}>
            <label>
              Don’t fill this out if you're human: <input name="bot-field" />
            </label>
            <input type="hidden" name="form-name" value="contact" />
          </p>
          <div className="field">
            <label className="field__label" htmlFor="name">
              Name:
            </label>
            <input className="field__input" type="text" name="name" />
          </div>
          <div className="field">
            <label className="field__label" htmlFor="email">
              Email:
            </label>
            <input className="field__input" type="text" name="email" />
          </div>
          <div className="field">
            <label className="field__label" htmlFor="phone">
              Phone:
            </label>
            <input className="field__input" type="text" name="phone" />
          </div>
          <div className="field">
            <label className="field__label" htmlFor="enquiry">
              Enquiry:
            </label>
            <textarea className="field__input" name="enquiry" />
          </div>
          <p style={{ textAlign: "right" }}>
            <button type="submit">Send</button>
          </p>
        </StyledForm>



        </StyledColumns>

       



        <GoogleMap siteMeta={site} />

      </div>
    </Layout>
  )
}


const StyledContactGroup = styled.div`

@media (max-width:700px){
  margin-bottom:30px;
  &:last-child{
    margin-bottom:0;
  }
}

h2{
  margin: 0 auto 0.5rem;
}
`


const StyledColumns = styled.div`
  display:flex;
  justify-content:space-between;
  margin:80px 0 40px;

  @media (max-width:700px){
    flex-direction:column;
  }

  & > div{
    width:50%;
    
  }
`




const StyledForm = styled.form`
  max-width: 650px;
  min-width:340px;
  

  .field {
    position: relative;
    margin-bottom: 25px;
    &:focus-within {
      .field__label {
        color: ${accent};
      }
    }
  }

  .field__label {
    position: absolute;
    top: 5px;
    left: 10px;
    font-size: 12px;
    color: #888;
  }

  .field__input {
    display: block;
    padding: 25px 10px 10px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #aaa;
    transition: border-color 0.3s;

    &:focus {
      border-color: ${accent};

      & + .field__label {
        color: ${accent};
      }
    }
  }

  button {
    background: ${accent};
    color: #fff;
    border: none;
    border-radius: 20px;
    padding: 8px 35px;
    border: 1px solid ${accent};
    text-transform: uppercase;
    transition: color 0.3s, background 0.3s;
    cursor: pointer;
    font-size: 0.8rem;

    &:hover {
      color: ${accent};
      background: #fff;
    }
  }

  textarea{
    min-height:150px;
  }
`

const StyledContactDetails = styled.div`
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 80px !important;
 

  a {
    color: ${accent};
  }

  h2{
    margin-top:0;
  }
`

export const CONTACT_US_PAGE_QUERY = graphql`
  {
    pageQuery: markdownRemark(
      frontmatter: { templateKey: { eq: "contact-us-page" } }
    ) {
      frontmatter {
        title
        metaDescription
      }
      html
    }
    siteQuery: site {
      siteMetadata {
        title
        businessAddress
        telephone
        telephoneReadable
        email
      }
    }
  }
`
