import React, { Component } from "react"

export default class GoogleMap extends Component {
  render() {
    return (
      <div>
        <iframe
          title={`${this.props.siteMeta.title} | ${
            this.props.siteMeta.businessAddress
          }`}
          frameBorder="0"
          style={{ width: "100%", height: "450px" }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2076.200593370981!2d-8.73946984729726!3d51.744889783941346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4844f4295bb446bb%3A0x29e841f257d8f257!2s25+S+Main+St%2C+Cloghmacsimon%2C+Bandon%2C+Co.+Cork%2C+P72+RW26%2C+Ireland!5e0!3m2!1sen!2suk!4v1565442219294!5m2!1sen!2suk"
          allowFullScreen
        />
      </div>
    )
  }
}
